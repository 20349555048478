module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-16105532-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-fathom/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"JUNWFIPG"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"candeo-design","short_name":"candeo","start_url":"/","background_color":"#726658","theme_color":"#726658","display":"minimal-ui","icon":"src/img/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"7f75e89a1e2ee113af6de650dbbab62d"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
